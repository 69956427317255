/* eslint-disable jsx-a11y/img-redundant-alt */
import smartContract from "../assets/img/smart-contracts.png";
import webDev from "../assets/img/web-development.png";
import mobileDev from "../assets/img/mobile-development.png";
import webDesign from "../assets/img/ui.png";
import security from "../assets/img/web-security.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import colorSharp from "../assets/img/mountain2.png";

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="skill">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn" id="skills">
              <h2>Skills</h2>
              <p>
                We pride ourselves in our ability to deliver a multitude of
                adapt software solutions and packages to best suit our clients
                need.<br></br> See the most common types of Angel projects below
              </p>
              <Carousel
                responsive={responsive}
                infinite={true}
                className="owl-carousel owl-theme skill-slider"
              >
                <div className="item">
                  <img src={smartContract} alt="Image" />
                  <h5>Solidity</h5>
                </div>
                <div className="item">
                  <img src={webDev} alt="Image" />
                  <h5>Web Development</h5>
                </div>
                <div className="item">
                  <img src={mobileDev} alt="Image" />
                  <h5>Mobile Development</h5>
                </div>
                <div className="item">
                  <img src={webDesign} alt="Image" />
                  <h5>Web Design - UI/UX</h5>
                </div>
                <div className="item">
                  <img src={security} alt="Image" />
                  <h5>Security Testing</h5>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  );
};
