import { Container, Row, Col } from "react-bootstrap";
import ResponsiveCarousel from "./Carousel";
import background from "../assets/img/portfolio-bg1.png";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const Projects = () => {
  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Blessing bestowed.</h2>
                  <p>
                    Previous projects, we've honor had the honor to work with.
                  </p>
                  <ResponsiveCarousel />
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
        <img
          className="background-image-right"
          src={background}
          alt="color sharp"
        />
      </Container>
    </section>
  );
};
