import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import Slide2 from "../assets/img/slides/Slide2.PNG";
import Slide3 from "../assets/img/slides/Slide3.PNG";
import Slide4 from "../assets/img/slides/Slide4.PNG";
import Slide5 from "../assets/img/slides/Slide5.PNG";
import Slide6 from "../assets/img/slides/Slide6.PNG";
import Slide7 from "../assets/img/slides/Slide7.PNG";
import Slide8 from "../assets/img/slides/Slide8.PNG";
import Slide9 from "../assets/img/slides/Slide9.PNG";
import Slide10 from "../assets/img/slides/Slide10.PNG";

export default function ResponsiveCarousel() {
  return (
    <div className="carousel-wrapper" style={{ textAlign: "center" }}>
      <Carousel infiniteLoop useKeyboardArrows autoPlay>
        {/* <div>
          <img src={Slide2} alt="asdsa" />
        </div> */}
        <div>
          <img src={Slide3} alt="asdsa" />
        </div>
        <div>
          <img src={Slide4} alt="asdsa" />
        </div>
        <div>
          <img src={Slide5} alt="asdsa" />
        </div>
        <div>
          <img src={Slide6} alt="asdsa" />
        </div>
        <div>
          <img src={Slide7} alt="asdsa" />
        </div>
        <div>
          <img src={Slide8} alt="asdsa" />
        </div>
        <div>
          <img src={Slide9} alt="asdsa" />
        </div>
        <div>
          <img src={Slide10} alt="asdsa" />
        </div>
      </Carousel>
    </div>
  );
}
